// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-camps-js": () => import("/opt/build/repo/src/pages/camps.js" /* webpackChunkName: "component---src-pages-camps-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-foundation-js": () => import("/opt/build/repo/src/pages/foundation.js" /* webpackChunkName: "component---src-pages-foundation-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-primary-parents-js": () => import("/opt/build/repo/src/pages/primary/parents.js" /* webpackChunkName: "component---src-pages-primary-parents-js" */),
  "component---src-pages-primary-schools-js": () => import("/opt/build/repo/src/pages/primary/schools.js" /* webpackChunkName: "component---src-pages-primary-schools-js" */),
  "component---src-pages-secondary-schools-js": () => import("/opt/build/repo/src/pages/secondary/schools.js" /* webpackChunkName: "component---src-pages-secondary-schools-js" */),
  "component---src-pages-secondary-students-and-parents-js": () => import("/opt/build/repo/src/pages/secondary/students-and-parents.js" /* webpackChunkName: "component---src-pages-secondary-students-and-parents-js" */),
  "component---src-pages-voices-js": () => import("/opt/build/repo/src/pages/voices.js" /* webpackChunkName: "component---src-pages-voices-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

